import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Categories = ({data, activeCategory, setActiveCategory}) => {
	const [listOpen, setListOpen] = useState(false)
	const popupRef = useRef()

	const onClickCat = (cat) => {
		setActiveCategory(cat)
		setListOpen(false)
	}

	useEffect(() => {
		let handleOutsideClick
		if(listOpen) {
			handleOutsideClick = (e) => {
				if(!popupRef.current ||	!popupRef.current.contains(e.target)) {
					setListOpen(false)
				}
			}
		document.addEventListener("click", handleOutsideClick)
		}

		return () => document.removeEventListener("click", handleOutsideClick)
	},[listOpen])

	return (
		<Wrapper>
			<h5>Categories: </h5>
			<FilterWrapper listOpen={listOpen}>
				<button onClick={() => setListOpen(!listOpen)}>
					{activeCategory}
				</button>
				<ul listOpen={listOpen} ref={popupRef}>
					{data.map(item => {
						const catName = item.document.data.name
						return (
							<li onClick={() => onClickCat(catName)}>
								<Link to={''}>{catName}</Link>
							</li>
						)
					})}
				</ul>

			</FilterWrapper>
		</Wrapper>
	)
}

export default Categories

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 5px;
	
	h5 {
		color: white;
		font-size: 18px;
		line-height: 28px;

    @media (min-width: ${({theme}) => theme.media.tablet_min}) {
      font-size: 20px;
      line-height: 32px;
    }
	}
`

const FilterWrapper = styled.div`
	position: relative;
	
	button {
		background: transparent;
		border: none;
    color: white;
    font-size: 18px;
    line-height: 28px;
		cursor: pointer;
		margin-left: 5px;

    @media (min-width: ${({theme}) => theme.media.tablet_min}) {
      font-size: 20px;
      line-height: 32px;
    }

    &:after {
      content: '';
      display: inline-block;
      margin-left: 10px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9834 1.99996L11.9917 11.9917L1.99993 1.99997' stroke='white' stroke-width='3.07438'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
      width: 15px;
      height: 10px;
	    transform: rotate(${props => props.listOpen ? "180deg" : "0"});
    }
	}

  ul {
    visibility: ${props => props.listOpen ? "visible" : "hidden"};
    max-height: ${props => props.listOpen ? "230px" : "0"};
    opacity: ${props => props.listOpen ? "1" : "0"};
    overflow: hidden;
    transition: ${({theme}) => theme.durations.transition};
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    background: #2A95BF;
	  min-width: 175px	;

    li {
      color: white;
      padding: 8px;
	    cursor: pointer;
      transition: ${({theme}) => theme.durations.transition};
	    position: relative;
	    
	    &::before {
        position: absolute;
		    content: "";
		    left: 0;
		    top: 0;
		    height: 100%;
		    width: 2px;
		    background: white;
		    opacity: 0;
        transition: ${({theme}) => theme.durations.transition};
	    }
	    
	    &:hover {
		    background: ${({theme}) => theme.colors.secondary_color};
        transition: ${({theme}) => theme.durations.transition};
		    
		    &::before {
			    opacity: 1;
          transition: ${({theme}) => theme.durations.transition};
		    }
	    }
    }
  }
`
