import React, { useEffect, useState } from "react"
import { MainBackground } from "../MainBackground"
import styled from "styled-components"
import Categories from "./Categories"
import { Container } from "../Container"
import AllAccordions from "./AllAccordions"

const Questions = ({ data }) => {
  const { categories, questions } = data
  const [answers, setAnswers] = useState([])
  const [activeCategory, setActiveCategory] = useState(
    questions[0]?.node?.data?.category.document.data.name
  )

  useEffect(() => {
    const answers = questions.filter(q => {
      return q?.node?.data?.category.document.data.name === activeCategory
    })

    setAnswers(answers)
  }, [activeCategory])

  return (
    <MainBackground type={"violet"} container>
      <Section>
        <Container>
          <Header>
            <h2>Faqs</h2>
            <Categories
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              data={categories}
            />
          </Header>
          <AllAccordions data={answers} background={"rgba(0, 84, 117, 0.8)"}/>
        </Container>
      </Section>
    </MainBackground>
  )
}

export default Questions

const Section = styled.section`
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
`

const Header = styled.div`
  @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
    margin-bottom: 30px;
  }

  h2 {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 15px;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 64px;
      line-height: 78px;
      margin-bottom: 30px;
    }
  }
`
